@tailwind base;
@tailwind components;
@tailwind utilities;


.text-wrap {
  position: relative;
  max-height: 4.5em; /* 3 lines of text */
  overflow: hidden;
}

.text-wrap::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 1em;
}
